export const useRandom = (id: string = '0') => {
  /**
   * Generates a number between 0 and 1
   */
  const teaserColor = parseInt(id.charAt(id.length - 1)) % 2 < 0.5;

  return {
    teaserColor,
  };
}
